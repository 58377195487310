import Image from 'next/image';
import { Box, Button, Typography } from '@mui/material';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { WithLazyLoadProps } from '@/utilities/withLazyLoad';
import { SxClassProps } from '@/types';
import budgetImage from '@/../public/rebranding/Budget_2x.jpg';
import { useFeatureFlags } from '@/components/contexts/FeatureFlagsContext';
import { CLIENT_FEATURE_FLAGS, PLANS_PRICING_URL } from '@/constants';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';

const {
  publicRuntimeConfig: { CZEN_GENERAL },
} = getConfig();

const classes: SxClassProps = {
  container: {
    paddingTop: {
      xs: 7,
      md: 13,
    },
    paddingBottom: {
      xs: 7,
      md: 13,
    },
    paddingX: {
      xs: 3,
      sm: 1,
      md: 4,
    },
    display: 'flex',
    justifyContent: 'center',
    maxWidth: {
      xs: '500px',
      sm: 'none',
    },
    boxSizing: 'content-box',
    marginX: 'auto',
  },

  wrapper: {
    flexDirection: {
      xs: 'column-reverse',
      sm: 'row-reverse',
      md: 'row',
    },
    gap: {
      xs: 4,
      sm: 5,
      md: 11,
    },

    height: {
      sm: '257px',
      md: '408px',
    },
    background: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '1062px',
  },

  budgetSection: {
    width: {
      xs: '100%',
      sm: '377px',
      md: '454px',
    },
  },

  headline: {
    height: {
      sm: '72px',
      md: '94px',
    },
    maxWidth: {
      xs: '292px',
      md: '417px',
    },
    fontSize: {
      xs: '28px',
      sm: '28px',
      md: '36px',
    },
    lineHeight: {
      xs: '34px',
      sm: '34px',
      md: '42px',
    },
    width: '100%',
    fontWeight: '700',
  },

  blueHighlight: {
    padding: {
      xs: '2px',
      sm: '4px',
      md: '4px',
    },
    display: 'inline-block',
    backgroundColor: '#E3EEFF',
    borderRadius: '8px',
  },

  description: {
    fontSize: {
      xs: '18px',
      sm: '21px',
      md: '21px',
    },
    lineHeight: {
      xs: '24px',
      sm: '28px',
      md: '28px',
    },
    marginTop: {
      xs: '24px',
      sm: '24px',
      md: '32px',
    },
    maxWidth: {
      md: '454px',
    },
    width: '100%',
    fontWeight: '400',
  },

  imageWrapper: {
    borderRadius: '16px',
    border: '1px solid #fff',
    overflow: 'hidden',
    width: {
      xs: '100%',
    },
    maxWidth: {
      sm: '327px',
      md: '519px',
    },
    flexBasis: {
      sm: '50%',
    },
  },

  learnMoreButton: {
    marginTop: {
      xs: '24px',
      sm: '32px',
    },
    width: {
      xs: '100%',
      sm: '184px',
    },
    height: '56px',
    borderRadius: '32px',
  },
};

function MembershipPlan({ hasLoaded }: WithLazyLoadProps) {
  const router = useRouter();
  const featureFlags = useFeatureFlags();
  const isPlansPricing = featureFlags.flags[CLIENT_FEATURE_FLAGS.PLANS_PRICING].value === 2;

  const learnMoreButtonHandler = () => {
    AnalyticsHelper.logEvent({
      name: 'CTA Interacted - VHP',
      data: {
        cta_location: 'VHP Body',
        cta_text: 'Learn more',
        enrollment_initiated: true,
        screen_name: 'Visitor Homepage',
        vertical: 'Unknown',
      },
    });
    router.push(`${CZEN_GENERAL}${PLANS_PRICING_URL}`);
  };

  return (
    <Box sx={classes.container}>
      <Box sx={classes.wrapper}>
        <Box sx={classes.budgetSection}>
          <Typography variant="h1" color="textPrimary" sx={classes.headline}>
            Customize your care to{' '}
            <Box component="span" sx={classes.blueHighlight}>
              fit your budget
            </Box>
          </Typography>
          <Typography variant="body1" color="textPrimary" sx={classes.description}>
            Choose a membership plan to find care from full-time, to part-time, to occasional.
          </Typography>
          {isPlansPricing && (
            <Button
              onClick={learnMoreButtonHandler}
              sx={classes.learnMoreButton}
              size="large"
              variant="contained"
              color="secondary">
              Learn more
            </Button>
          )}
        </Box>
        <Box sx={classes.imageWrapper}>
          {hasLoaded && <Image src={budgetImage} alt="My Image" layout="responsive" />}
        </Box>
      </Box>
    </Box>
  );
}

export default MembershipPlan;
